import { Container} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke} from "../ContentStyles";
import {Pageheight} from "../../index";

import React from 'react';

const NoPage = () => {
    return (
        <ContentWrapper>
        <Container>
        <Sida style={{minHeight:Pageheight(400)}}>

            <Rubrik>Sidan saknas (404)</Rubrik>
            <Stycke>Det verkar som att du skrivit in en felaktig adress, använd gärna vår meny högst upp på sidan!
                <br></br><a href="https://sv.wikipedia.org/wiki/404_error">https://sv.wikipedia.org/wiki/404_error</a>
            </Stycke>                

        </Sida>
        </Container>
        </ContentWrapper>
    );
};

export default NoPage;